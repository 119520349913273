.progress-base {
  width: 100%;
  background: rgba(0, 0, 0, 0.08);
}

.progress-overlay {
  background: #5dbcfb;
  transition: 0.5s;
}

.progress-base,
.progress-overlay {
  height: 15px;
  border-radius: 22px;
}
