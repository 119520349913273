.game {
  height: 100%;
  font-family: "Baloo Chettan", cursive;
  background-size: cover;
  background-image: url("../../assets/images/Theme/Blue/bg1.svg");
  display: flex;
}

.game[bg="green"] {
  background-image: url("../../assets/images/game/map-bg.svg");
  background-color: #b8e5ff;
}
.game[bg="b"] {
  background-image: url("../../assets/images/Theme/Green/bg1.svg");
}
.game[bg="yellow"] {
  background-image: url("../../assets/images/Theme/Yellow/bg1.svg");
}
.game[bg="red"] {
  background-image: url("../../assets/images/Theme/Red/bg1.svg");
}
/* .game[bg="blue"] .game-view {
  background-image: url("../../assets/images/Theme/Blue/bg1.svg");
} */
.quiz-page {
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
}

.exit {
  position: absolute;
  top: 2%;
  left: 1%;
}
.exit path {
  transition: 0.2s;
}

.exit:hover {
  cursor: pointer;
}
.exit:hover path {
  stroke: rgb(35, 81, 159);
}

.game-view {
  width: 85%;
  height: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
}
.game-view-multipleChoice {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* background-color: lime; */
}

.game-view-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.quiz-header {
  display: flex;
  flex-direction: row;
  width: 97%;
  height: 50px;
  min-height: 50px;
  margin: 0 auto;
  margin-top: 10px;
}

.quiz-header h2 {
  text-align: center;
}

.game-map-container svg {
  position: relative;
}

.quiz-map {
  display: inline-flex;
  align-self: center;

  /* max-height: 100%; */
  width: 85%;
  height: 80%;
  margin: auto auto;

  min-width: 900px;
  border-radius: 21px;
  grid-area: qm;
  /* background: rgba(51, 74, 131, 0.152); */

  /* position: relative;
  z-index: 0;
  top: -100px;
  right: 5%; */
}
.quiz-map-multipleChoice {
  display: inline-flex;
  /* align-self: flex-end; */
  width: 75%;
  height: 80%;

  min-width: 400px;
  border-radius: 21px;
  grid-area: qm;
  /* background-color: rgb(237, 237, 237); */
}
.quiz-map-multipleChoice .game-map-container {
  width: 65%;
  margin: 0;
  /* backgound-color: blue; */
}
.quiz-map svg,
.quiz-map-multipleChoice svg {
  max-height: 100%;
  overflow: visible;
}
.quiz-map-multipleChoice-options-side {
  width: 45%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}
.quiz-map-multipleChoice-options-container {
  width: 60%;
  align-self: center;
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-right: 30px;
}
.quiz-map-multipleChoice-options-container h2 {
  color: #787878;
  font-size: 24px;
  text-align: center;
}
.quiz-choice {
  display: flex;
  min-width: 80%;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 16px;
  border: 4px solid #f4f4f4;
  margin-bottom: 15px;
  align-items: center;
  padding: 20px;

  transition: all 0.5s;
}
.quiz-choice:hover {
  background-color: #6bd377;
  border-color: #6bd377;
  cursor: pointer;
}
.quiz-choice:hover p {
  color: white;
}
.quiz-choice p {
  font-size: 24px;
  color: #989898;
  margin: 0;
}
.quiz-choice h2 {
  color: #454545;
  font-size: 24px;
  margin: 0;
  margin-left: 30px;
  /* white-space: nowrap; */
}

.shadow {
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  /* Similar syntax to box-shadow */
}

.main-container {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-areas:
    "qt qt qt qt qt qt"
    "qs qm qm qm qm qm"
    "qs qm qm qm qm qm"
    "qs qm qm qm qm qm"
    "qs qm qm qm qm qm"
    "qs qm qm qm qm qm";
}

.quiz-size {
  display: flex;
  flex: 1;
}

.other {
  pointer-events: none;
  /* fill: #5f5f5f98; */
  /* fill: #f1f1f1; */
  fill: rgba(25, 38, 29, 0.5);
}
#Misc path {
  fill: none;
  stroke: rgba(0, 0, 0, 0.459);
}

.quiz-country[data-small="true"] {
  /* outline: 2px solid rgb(11, 19, 45); */
}

/* .quiz-country[data-attempts="1"] {
  fill: #ffed72 !important;
  stroke: none;
} */

/* .quiz-country[data-attempts="2"] {
  fill: #ffa3a3 !important;
  stroke: none;
} */
/* .quiz-country[data-col="#285979"]:hover {
  fill: #3A7195;
}
.quiz-country[data-col="#3A7195"]:hover {
  fill: #285979;
} */

.game-map {
  height: 75%;
}

.game-map .other[data-modify="true"] {
  fill: rgba(111, 111, 111, 0.317);
}

.quiz-line {
  pointer-events: none !important;
}

.quiz-country path {
  stroke: #b8e5ff;
  stroke-width: 0.5px;
  /* stroke-width: 1.5px; */
}

.ripple {
  /* background-position: center; */
  /* transition: background 0.8s; */
  animation: ripple1 1s linear;
  transform-origin: center center;
}
.ripple:hover {
  background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%)
    center/15000%;
}
.ripple:active {
  background-color: #6eb9f7;
  background-size: 100%;
  transition: background 0s;
}

@keyframes ripple1 {
  0% {
    fill: "grey" !important;
    opacity: 0.5;
    /* fill: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000% !important; */
    /* transform: scale(0.5); */
    /* opacity: 1; */
  }
  100% {
    fill: unset !important;
    opacity: 1;
    /* transform: scale(1); */
    /* fill: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000% !important; */
    /* opacity: 1; */
  }
}

.wrongSelect {
  position: absolute;
  animation: shake 0.2s;
  -webkit-animation: shake 0.2s;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
    -webkit-transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
    -webkit-transform: translate(-1px, -2px) rotate(-1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
    -webkit-transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
    -webkit-transform: translate(1px, -1px) rotate(1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
    transform: translate(-1px, -1px) rotate(1deg);
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
    -webkit-transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
    -webkit-transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
  }
}

[data-dist-low]:hover {
  fill: #ff9a9a !important;
}
[data-dist-med]:hover {
  fill: #f8d5b6 !important;
}
[data-dist-high]:hover {
  fill: #eef8b6 !important;
}
[data-dist-veryHigh]:hover {
  fill: #b8e9a1 !important;
}
[data-dist-perfect]:hover {
  fill: #47ff5a !important;
}

[data-distance-score]:hover {
  fill: attr(data-distance-score color, green) !important;
}
