.quiz-infos-container {
  /* display: flex;
  flex-direction: row;
  justify-content: space-between; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  margin-top: 0.2%;
}

.quiz-info-scores {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.quiz-info-scores p {
  display: inline-flex;
  flex: 1;
}

/* .quiz-info-scores span {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 25px;
} */
.score {
  margin-left: 10px;
}
.score p {
  margin: 0;
}

.quiz-infos-find {
  height: 100%;
  width: 100%;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  white-space: nowrap;

  /* justify-self: center; */

  position: relative;
  z-index: 50;
  top: 100%;
}

.quiz-infos-find h2 {
  color: rgba(10, 10, 10, 0.35);
  font-size: 24px;

  position: relative;
  top: 10px;
}

.info-boxed {
  border-radius: 4px;
  padding: 0 15px;
  margin: 0;
  font-size: 24px;
  display: inline-block;
  margin-left: 40px;
}

.find-flag {
  width: 70px;
  height: 45px;
  border-radius: 8px;
  margin-left: 20px;
  margin-right: 50px;
}

.quiz-helpers {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  width: auto;
  min-width: 15%;
  justify-self: flex-end;
}

.quiz-helpers div {
  /* background-color: #fff; */
  color: #424242;
  font-size: 24px;
  /* box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25); */
  margin-right: 4%;
  border-radius: 15px;
  border: 4px solid #81cdff;
  background-color: rgb(255, 255, 255, 0);
  box-sizing: border-box;
}

.quiz-helpers div:hover {
  cursor: pointer;
  background-color: #81cdff3c;
  color: rgb(36, 66, 113);
  /* border-color: #b3daf4; */
  /* box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25); */

  /* background-color: rgb(240, 240, 240); */
}

.showme-btn {
  padding: 0 20px;
  white-space: nowrap;
  user-select: none;
}

.circle-btn {
  width: 85px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.circle-btn svg {
  width: 50%;
  height: 50%;
}
.circle-btn svg path {
  fill: #424242;
}

.circle-btn:hover svg path {
  fill: rgb(36, 66, 113);
}

#find-place {
  font-size: 42px;
  color: #000;
  margin-left: 10px;
}

.quiz-side-container {
  grid-area: qs;
  background-color: rgba(83, 174, 83, 0.358);
}

.quiz-side-infos {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
  margin-top: 50px;
}

.quiz-side-infos span {
  display: inline-flex;
  flex-direction: column;
  margin: 0 auto;
}

.quiz-boxed-text {
  display: inline-block;
  background: #ffffff;
  border: 2px solid #a6ddff;
  box-sizing: border-box;
  border-radius: 14px;
  font-size: 30px;
  color: #009cff;
  padding: 0px 10px;
}

#quiz-infos-find {
  display: inline-flex;
  flex-direction: row;
  align-content: center;
  min-width: 280px;
  text-align: center;

  background: #beffb5;
  box-shadow: 0px 5px 1px rgba(5, 117, 2, 0.25);
  border-radius: 16px;

  padding: 5px 35px;
  margin: 0 auto;
}

#quiz-find {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 58px;
  color: #20693e;
}

#quiz-infos-find img {
  width: 60px;
  height: 40px;
  border-radius: 5px;
  margin-left: 15px;
  align-self: center;
}

.quiz-infos-score {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-size: 1.7em;
  border-top: 2px solid #435159;
  border-bottom: 2px solid #435159;
  width: 300px;
}
/* .quiz-infos-score p {
  margin: auto 0;
} */

.quiz-info-scores-text {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 28px;
  color: #454545;
  margin-top: 3%;
}
.quiz-info-scores-text p {
  margin: 0 30px;
}
.quiz-info-scores-text span {
  background-color: #5dbcfb;
  color: white;
  border-radius: 15px;
  padding: 0 10px;
}

.small-place-panel {
  max-width: 200px;
  height: auto;
  display: inline-flex;
  flex-wrap: wrap;
  /* position: relative;
  top: 30%;
  right: -3%; */
  justify-content: flex-start;
  align-content: center;
}

.small-place-btn {
  width: 95px;
  height: 80px;
  border-radius: 34px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.small-place-btn.disabled-btn {
  pointer-events: none;
  background: none;
}
.small-place-btn.disabled-btn circle {
  fill: rgb(189, 189, 189);
  stroke: rgb(189, 189, 189);
}
.small-place-btn.disabled-btn path {
  fill: grey;
}

.small-place-btn:hover {
  cursor: pointer;
  background: rgba(64, 141, 149, 0.15);
}
.small-place-btn:hover circle {
  stroke: #5ccb81;
}
/* .small-place-btn:hover svg {
  border: 2px solid red;
} */
