#root,
.App {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow-x: hidden;
}

.home-link {
  font-size: 25px;
  color: white;
  font-family: "Baloo Chettan", cursive;
  background-color: #a4c5e4;
  border-radius: 15px;
  margin-left: 30px;
  align-self: center;
}

.header-logo img {
  height: 100%;
}

.home-link:hover {
  text-decoration: none;
  color: white;
  opacity: 0.7;
}
