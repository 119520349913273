$small: 300px;
$medium: 900px;

.game {
  display: flex;
}

.quizPage {
  display: flex;
  flex-direction: column;
  // height: 100%;
  max-height: 100vh;
  // min-height: 100vh;
  width: 100%;
  background-color: #addfeb;
  // overflow: hidden;
}

.headerContainer {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;

  .headerFind {
    display: flex;
    flex: 1;
    // padding: 0 10px;
    // position: absolute;
    // top: 60px;
    padding: 20px;
    align-items: center;
    justify-content: center;
    // min-width: 300px;
    // width: 100%;
    // margin-top: 5px;
    // margin-left: 5px;
    // border-radius: 6px;

    // width: 100%;

    .headerFindSub {
      font-size: 16px;
      margin-right: 15px;
      margin-left: -15px;
      align-self: center;
    }

    div {
      display: inline-flex;
      color: #142441;
      font-family: "Nunito", sans-serif;
      font-weight: bold;
      font-size: 24px;
      margin-left: 15px;
    }

    p {
      display: inline-flex;
      color: #989898;
      margin: 0;
      font-size: 16px;
      // background: rgba(255, 255, 255, 0.37);
      // background-color: white;
      // border-radius: 8px;
      // padding: 10px 30px;
      margin-left: 15px;
    }

    span {
      color: #142441;
      font-size: 25px;
      font-weight: bold;
      margin-left: 20px;
    }

    img {
      margin-left: 15px;
      height: 35px;
      align-self: center;
      border-radius: 5px;
    }
  }
}

.quizHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 70px;
  background-color: white;
  font-family: "Nunito", sans-serif;
  align-items: center;
  padding: 0 20px;

  .leftBar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1;

    > * {
      margin-right: 15px;
    }
  }

  > * {
    // margin: 0 20px;
    &:not(:first-of-type) {
      // border-left: 1px solid #e4e4e4;
    }
  }

  .quizHeaderExit {
    display: flex;
    align-items: center;

    svg {
      height: 100%;
      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }

  .quizHeaderScoreBox {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .quizHeaderScore {
    display: flex;
    color: #817d7d;
    align-items: center;
    text-align: center;

    > p {
      margin-right: 25px;
      margin-bottom: 0;
      font-weight: 700;

      @media screen and (max-width: 800px) {
        display: none;
      }
    }
    div {
      color: white;
      background-color: #5dbcfb;
      // height: 35px;
      min-width: 50px;
      font-size: 18px;
      box-shadow: 0px 0px 0px 5px rgba(187, 231, 255, 0.58);
      border-radius: 10px;
    }
  }

  .quizHeaderBar {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 40%;
    color: #5dbcfb;
    font-size: 15px;
    flex: 2;
    p {
      margin: 0;
    }
    @media screen and (max-width: 500px) {
      display: none;
    }
  }

  .quizHeaderTime {
    display: flex;
    color: #817d7d;
    align-self: center;

    p {
      margin: 0;
    }
  }

  .learnTitle {
    display: flex;
    text-align: left;
    width: 100%;
  }

  .quizHeaderButtons {
    display: flex;
    flex: 1;

    .muteIcon {
      box-shadow: none;
      background-color: unset !important;
      padding: 0;
      svg {
        path {
          fill: #454545;
        }
      }
    }

    > * {
      margin-left: 20px;
      svg {
        path {
          fill: #454545;
        }
      }

      @media screen and (max-width: 400px) {
        // margin-left: 5px;
      }
      &:active {
        transform: translateY(2px);
      }

      &:focus {
        outline: none;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }

    div {
      display: flex;
      align-items: center;
    }

    path {
      fill: white;
    }

    button {
      border: none;
    }
  }
}

.banner {
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 87%;
  left: 0%;

  .bannerLeft {
    position: relative;
    left: 20px;
    top: 10px;
    z-index: 1;
  }
  .bannerRight {
    transform: scaleX(-1);
    position: relative;
    right: 20px;
    top: 10px;
    z-index: 1;
  }

  .bannerBox {
    display: flex;
    align-items: center;
    background-color: #e86d6d;
    height: 42px;
    width: auto;
    min-width: 200px;
    position: relative;
    z-index: 2;
  }
  .bannerBoxText {
    width: 100%;
    text-align: center;
    color: white;
    font-weight: bold;
    font-family: "Nunito", sans-serif;
    font-size: 20px;

    span {
      &:not(:first-of-type) {
        margin-left: 10px;
      }
    }
  }
}

.bgExtra {
  position: absolute;
}

.gameView {
  display: flex;
  flex: 1;
  // display: flex;
  // flex: 0.9;
  // height: calc(100vh - 70px);
  // height: calc(100vh - 120px);
  justify-content: flex-start;
  overflow-x: scroll;
  // min-width: 800px;
  // background-repeat: repeat;
  // background-image: url("../../assets/images/game/map-bg.svg");
  // background-color: #aae0ff;
  // padding-bottom: 15px;

  &[data-quiz_type="learn"] {
    g {
      &:hover {
        cursor: default;
      }
    }
  }

  .gameMapContainer {
    // max-height: 80vh;
    // margin-bottom: 40px;
    width: 100%;
    display: flex;
    // min-width: 800px;
    // overflow-x: scroll;
    // height: 100%;
    // background-image: url("../../assets/maps/europe_bg.svg");
    background-repeat: no-repeat;
    background-position: 50% -40px;
    background-size: contain;
    background-image: none !important;
    justify-content: center;
    @media screen and (max-width: $medium) {
      justify-content: left;
      // align-self: unset;
    }
    // padding: 30px;

    &[data-has_smalls="true"] {
      justify-content: flex-start;
      background-position: -5% -40px;
      margin-left: 60px;
    }

    svg {
      height: 100%;
      position: relative;
      width: 100%;
      min-width: 800px;
      max-height: 80vh;

      &[data-locked="true"] {
        pointer-events: none;

        g:hover {
          cursor: default;
        }
      }
    }
  }

  .gameMultipleChoice {
    display: flex;
    width: 100%;
    flex-direction: column-reverse;
    justify-content: space-between;
    // height: 98%;

    svg {
      min-width: 500px;
      overflow-x: scroll;
    }

    .gameMapContainer {
      flex: 2;
      // min-width: 800px;
      max-height: 70%;
      background-position: 50% -10px;
      width: unset;
      height: unset;
      display: flex;
      background-image: none;
      padding: 0px;
      // pointer-events: none;
      position: unset;
      justify-content: center;

      @media screen and (max-width: 500px) {
        overflow-x: scroll;
        justify-content: left;
      }

      // g {
      //   fill: rgba(111, 177, 145, 0.58) !important;
      //   &[data-answer="true"] {
      //     fill: #F5E158 !important;

      //     &[data-small="true"] {
      //       outline: 8px solid #F5E158;
      //       transform-origin: center;
      //     }

      //     path {
      //       stroke: white !important;
      //       stroke-width: 2px;
      //     }

      //   }
      // }
    }

    .multipleChoiceSide {
      width: 100%;
      max-width: 400px;
      height: 200px;
      align-self: center;
      // border: 1px solid white;
      flex: 1;
      z-index: 9;

      @media screen and (max-width: $medium) {
        // align-self: unset;
      }
    }

    .multipleChoiceBoxes {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
      width: auto;
      &[data-selected="false"] {
        .box:hover {
          cursor: pointer;
          box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.22);
        }
      }

      .box {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #454545;
        height: 50px;
        width: 100%;
        margin: 10px;
        border-radius: 8px;
        background-color: white;
        border: none;
        font-weight: bold;
        letter-spacing: 0.095em;
        font-family: Nunito;

        &[data-correct="true"] {
          background-color: #6fb191;
          color: white;
        }
        &[data-correct="false"] {
          background-color: #e86d6d;
          color: white;
        }

        &:focus {
          outline: none;
        }

        &:active {
          background-color: #ebebeb;
        }

        div:first-of-type {
          border: #989898 1px solid;
          border-radius: 8px;
          padding: 2px;
          font-size: 16px;
          align-self: left;
          margin-left: 10px;
          color: #989898;
          width: 30px;
          height: 30px;
          @media screen and (max-width: $medium) {
            display: none;
          }
        }

        div:last-of-type {
          font-size: 16px;
          width: calc(100% - 40px);
        }
      }
    }
  }

  .correct {
    .box[data-status="correct"] {
      animation: correct ease-in 0.9s;

      div {
        color: white;
      }
      div:first-of-type {
        border: #ffffff 1px solid;
        color: #ffffff;
      }
    }
  }
  .wrong {
    .box[data-status="wrong"] {
      animation: wrong ease-in 0.9s;

      > div {
        // color: white !important;
        font-weight: bold;
      }
      div:first-of-type {
        border: #ffffff 1px solid;
      }
    }
  }

  .animateIn {
    button:nth-child(1) {
      animation: animateIn ease-in-out 0.4s;
      animation-delay: 0ms;
    }
    button:nth-child(2) {
      animation: animateIn ease-in-out 0.4s;
      animation-delay: 0.1s;
    }
    button:nth-child(3) {
      animation: animateIn ease-in-out 0.4s;
      animation-delay: 0.2s;
    }
  }
}

@keyframes animateIn {
  50% {
    opacity: 50%;
    // color: rgba(245, 245, 245, 0.144);
  }
  80% {
    // color: whitesmoke;
  }
  100% {
    opacity: 100%;
  }
}

@keyframes correct {
  30% {
    background-color: #67c5b5;
    box-shadow: 0px 0px 1px 8px #a6ddd4;
  }
}

@keyframes wrong {
  30% {
    background-color: #67c5b5;
    box-shadow: 0px 0px 1px 8px #a6ddd4;

    // box-shadow: 0px 0px 1px 8px rgba(221, 166, 180, 0.8);
  }
  70% {
    background-color: #67c5b5;
    // box-shadow: 0px 0px 1px 8px rgba(221, 166, 180, 0.8);
    box-shadow: 0px 0px 1px 8px #a6ddd4;
  }
}

.toolTip {
  font-family: "Baloo Chettan", cursive;
  font-size: 15px !important;
  letter-spacing: 0.06em;
  // text-transform: uppercase;
  font-weight: normal;
  background: rgba(49, 146, 40, 0.671) !important;
  border-radius: 14px !important;
  color: white;
  height: 30px;
  padding: 3px 10px !important;

  .place-bottom::before {
    border-left: 10px solid rgba(0, 0, 0, 0.29) !important;
  }
}

.learnToolTip {
  height: 60px;
  padding: 10px 20px;
  font-weight: bold;
  font-size: 24px !important;
}

.gamePoints {
  position: absolute;
  font-size: 25px;
  font-weight: 900;
  font-family: "Nunito", sans-serif;
  pointer-events: none;
  z-index: 3;
  margin-bottom: 0;
  opacity: 0;
}

.quizCircle {
  position: absolute;
  font-size: 25px;
  font-weight: 900;
  font-family: "Nunito", sans-serif;
  pointer-events: none;
  z-index: 3;
  margin-bottom: 0;
  opacity: 0;
}
