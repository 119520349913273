.end-game-modal {
  background: rgba(32, 45, 79, 0.7) !important;
}

.pre-game-modal,
.end-game-modal {
  // background: radial-gradient(51.22% 67.68% at 50% 50%, rgba(61, 85, 149, 0.5554) 0%, rgba(32, 45, 79, 0.81) 100%);
  // background: rgba(28, 43, 82, 0.884);
  background: rgba(32, 45, 79, 0.6);
  backdrop-filter: blur(2px);
  font-family: "Nunito", "Sans-serif";
  width: 80%;
  .modal-dialog {
    width: 100%;
    //  height: 100%;
    padding: 0;
    margin: auto;
  }

  .modal-content {
    height: auto;
    min-height: 50%;
    border-radius: 0;
    background-color: unset;
    border: none;
    // max-width: min-content;
    margin: 0 auto;
  }

  #pre-game-title,
  #end-game-title {
    display: flex;
    flex-direction: column;
    color: white;
    margin-bottom: 26px;
    // background: #172C5E;

    .pre-game-title-top {
      // width: 80%;
      display: flex;
    }

    #modal-title-sub {
      color: #7f8faa;
    }

    #modal-back {
      // flex: 0.5;
      color: #5dbcfb;
      // background-color: #193E97;
      position: relative;
      margin-bottom: 16px;
      width: 90px;
      padding: 4px;
      // margin-right: 20%;

      &:hover {
        background-color: #5dbcfb1a;
        border-radius: 12px;
        cursor: pointer;
      }
    }
    #modal-title-text {
      // position: relative;
      // flex: 1;
      // left: 10%;
    }
    #modal-title-title {
      flex: 0.6;
    }

    #modal-title-title {
      display: flex;
      width: 75%;
      justify-content: flex-end;
    }

    #modal-sub {
      color: #7f8faa;
    }

    #end-modal-sub {
      font-size: 2.8em;
      color: white;
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    min-width: 414px;
    margin: auto;
    padding: 2rem;
    // background: rgba(0, 32, 111, 0.26);
    border-radius: 0px 0px 20px 20px;

    // > * {
    //   margin-bottom: 50px;
    // }
  }

  .pre-modal-modes {
    // width: 50%;
    display: flex;
    background-color: none;
    margin-bottom: 16px;
  }

  @media screen and (max-width: 700px) {
    .pre-modal-modes {
      flex-direction: column;
    }
  }

  .end-modal-stats {
    width: 100%;
    margin-bottom: 16px;
  }

  .pre-modal-length {
    display: flex;
    flex-direction: column;
    // margin-top: 30px;
    margin: 0;
    .dropdown-container {
      display: flex;
      color: white;
    }

    span {
      margin-right: 10px;
      color: #d3e1f8;
    }
  }

  .dropdown-list {
    width: 100%;
    color: #7f8faa;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;

    p {
      margin-right: 4px;
      margin-bottom: 4px;
    }
  }

  .length-dropdown {
    font-family: "Nunito";
  }

  .modal-mode-horizontal {
    display: flex;
    height: 60px;
    // line-height: 32px;
    font-family: "Nunito";
    color: white;
    white-space: nowrap;
    padding: 0 20px;
    padding-top: 10px;

    &:not(:last-of-type) {
    }

    &:hover {
      cursor: default;
      &[data-selected="false"] {
        border-radius: 0px;
        cursor: pointer;
        background-color: #5dbcfb1a;
      }
    }

    transition: 0.1s;

    &[data-selected="true"] {
      border-bottom: 5px solid #5dbcfb;
    }
  }

  .modal-mode {
    display: flex;
    width: 100%;
    color: white;
    font-size: 1.5em;
    margin-top: 40px;
    padding: 20px;
    background-color: none;

    &:hover {
      color: rgb(170, 206, 206);
      .modal-mode-radio {
        border-color: rgb(170, 206, 206);
      }
    }

    &[data-selected="true"] {
      color: cyan;
      .modal-mode-radio {
        border-color: cyan;

        .radio-inner {
          border-radius: 50%;
          background-color: cyan;
          margin: 15%;
          width: 70%;
          height: 70%;
          transition: 0.2s;
        }
      }
    }
    &[data-selected="false"] {
      .modal-mode-radio {
        .radio-inner {
          //   display: none;
          margin: 30%;
          width: 40%;
          height: 40%;
        }
      }
    }

    p {
      flex: 5;
    }

    .modal-mode-radio {
      //   flex: 1;
      width: 40px;
      height: 40px;
      border: 3px solid white;
      //   background-color: lime;
      border-radius: 50%;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.end-game-modal {
  min-width: 60vw;
  .modal-stat {
    display: flex;
    width: 100%;
    color: #bababa;
    font-size: 24px;
    // margin-top: 40px;

    .modal-stat-name {
      flex: 4;
    }
    .modal-stat-value {
      text-align: end;
    }
  }
  .end-modal-stats {
    display: flex;
    flex-direction: column;
  }
}
