html,
body {
  /* overflow-x: hidden; */
  width: 100%;
  height: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  /* font-family: "Amiko", sans-serif; */
  font-family: "Baloo Chettan", cursive;
  font-weight: bold;
}

.home-page {
  width: 100%;
  height: 100%;
  /* font-family: "Baloo Chettan", cursive; */
  /* min-height: 100vh; */
}

.home-page-top {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* min-height: 100vh; */
  /* min-height: 100vh; */
}

#world-img {
  /* width: 35%; */
  /* height: 10%; */
  width: 500px;
  height: 500px;
}

.home-page-top-content-container {
  display: flex;
  flex-grow: 2;
  justify-content: center;
  background-color: #103c59;
  background-image: url("../assets/images/homeStars.svg");
  width: 100%;
  height: 100%;
  padding: 70px 0 50px 0;
}

.home-page-top-content {
  display: flex;
  min-height: 85vh;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  max-width: 170rem;
  margin: auto 0;
}

.home-page-top-wave {
  display: flex;
  flex-grow: 1;
  height: 100px;
  min-height: 5vw;
  width: 100%;
  background-color: #113d5a;
}

@media screen and (max-width: 500px) {
  .home-page-top-wave {
    display: none;
  }
}

.wave {
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
  pointer-events: none;
}

#top-wave {
  width: 100%;
  height: 100%;
}

/* @media (max-width: 1200px) {
  .home-page-top-content {
    flex-direction: column-reverse;
    justify-content: center;
  }
  #world-img {
    max-width: 500px;
    max-height: 500px;
    width: 100%;
    height: 100%;
  }
} */

.hr {
  position: relative;
  margin: 30px 0;
  width: 30%;
  border-bottom: 2px solid #e5e5e5;
}

.divider {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  color: #c5c5c5;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 50px;
}
