@keyframes pulse-white {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

/* .quiz-country[data-answer="true"] {
  fill: #abdeff;
  stroke: #5dbcfb;
  stroke-width: 3px;
} */

.pulsey {
  fill: white;
  fill-opacity: 0;
  /* transform-origin: 50% 50%; */
  transform-origin: center;
  animation-duration: 0.5s;
  animation-name: pulsey;
  /* animation-iteration-count: infinite; */
}

@keyframes pulsey {
  from {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  to {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
}

.quiz-country:hover path {
  /* fill: rgb(252, 5, 5) !important; */
}

.ring-helper:hover {
  stroke-width: 3px;
}

/* .quiz-country circle {
    stroke: black; 
    fill: grey;
    stroke-width: 2px;
    stroke-opacity: 1;
  } */

.wipe {
  animation-duration: 1s;
  animation-name: wipey;
  background-position: left bottom;
  background-color: rgb(243, 255, 206);
}

@keyframes wipey {
  0% {
    background-color: rgb(243, 255, 206, 0);
  }
  50% {
    background-color: rgb(243, 255, 206, 1);
  }
  100% {
    background-color: rgb(243, 255, 206, 0);
  }
}

.animated {
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
}
.animated.hinge {
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
}
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes pulse {
  0% {
    -moz-transform: scale(1);
  }
  50% {
    -moz-transform: scale(1.1);
  }
  100% {
    -moz-transform: scale(1);
  }
}
@-o-keyframes pulse {
  0% {
    -o-transform: scale(1);
  }
  50% {
    -o-transform: scale(1.1);
  }
  100% {
    -o-transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    transform: scale(2);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
