.container {
  width: 100%;
  height: 100%;
  max-width: 1000px;
  background-color: white;
  display: flex;
  flex-direction: column;
  margin: 0 16px !important;
}

.list-top {
  display: flex;
  width: 100%;
  margin-bottom: 40px;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }

  .top-title {
    font-size: 26px;
    min-width: fit-content;
    margin-top: 30px;
    color: #425459;
    font-weight: 400;
    text-align: left;
    flex: 2;
    @media screen and (max-width: 500px) {
      text-align: center;
    }
  }

  .top-sections {
    flex: 2;
    display: flex;
    flex-direction: row;
    // justify-content: space-between;

    .top-section-block {
      width: 100px;
      height: 35px;
      box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      color: #425459;
      text-align: center;
      padding-top: 5px;

      &:not(:last-of-type) {
        margin-right: 10px;
      }

      &:hover {
        background-color: whitesmoke;
        cursor: pointer;
      }

      &[data-selected="true"] {
        background-color: #6fb191;
        pointer-events: none;
        color: #fff;
      }
    }
  }
}

.list-contents {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  grid-gap: 70px;
}

.list-card {
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-rows: 1fr;
  background-color: white;
  width: auto;
  min-width: 130px;
  height: 180px;
  box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.25);
  border-radius: 18px;

  &:hover {
    background-color: #f2f2f2;
    box-shadow: none;
    cursor: pointer;
  }
}
