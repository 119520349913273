.simple-button {
  border-radius: 12px;
  color: white;
  font-size: 24px;
  padding: 10px 80px;
  border: none;
  white-space: nowrap;
  max-width: 400px;

  &:focus {
    outline: none;
  }

  &:hover {
    opacity: 0.9;
  }
}