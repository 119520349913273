.home-mainBtns {
  width: auto;
  height: auto;
  display: inline-block;
  border-radius: 30px;
  padding: 20px;
  z-index: 1;
  color: white;
  /* font-family: 'Amiko', sans-serif; */
  /* font-family: "Baloo Chettan", cursive; */
  font-family: "Nunito", sans-serif;
}

#home-slogan {
  font-size: 26px;
  font-weight: 700;
  /* font-size: calc(2vw + 2vh); */
}

.home-slogan-subtext {
  font-size: 16px;
}

.home-btns-panel {
  border-radius: 10px;
  color: white;
  font-size: 16px;
}

.home-btns-panel p {
  justify-self: flex-start;
  margin-bottom: 40px;
}

.home-btns-panel button {
  margin-top: 20px;
  font-weight: 800;
  font-size: 16px;
}

#home-learnBtn {
  background: #98e575;
  font-size: 25px;
  box-shadow: 0px 4px 1px #88cc69;
  border-radius: 24px;
}

#home-learnBtn:hover {
  background: #93f566;
}

#home-learnBtn:active {
  background: #85cd64;
}

#home-quizBtn {
  background: #2A9582;
  box-shadow: 0px 5px 0px #fff;
  border-radius: 24px;
}

#home-quizBtn:hover {
  background: #1e7162;
  box-shadow: 0px 4px 0px #fff;
}

#home-quizBtn:active {
  background: #175f51;
  box-shadow: 0px 0px 0px #fff;
  transition: 0.03s;
}

.selectable {
  color: white;
  width: 150px;
  height: 50px;
  text-align: center;
  line-height: 10px;
  background: #98e575;
  border-radius: 30px;
  outline: none;
  border: none;
  white-space: nowrap;
}

.selectable:focus {
  outline: none;
}
