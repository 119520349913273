// .quiz-country.complete {
//   box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
//   transform-origin: unset;
//   animation: wipey 1.5s;
// }

.small-helper {
  // fill: rgb(98, 128, 148);
  fill: rgba(62, 125, 83, 0.56);
}
.small-helper:hover {
  fill: #3298db;
  opacity: 0.6;
}

.quiz-country.complete {
  fill: #fff;
  stroke: #a6ddd4;
  stroke-width: 3px;
  pointer-events: none !important;

  .small-helper {
    fill: white;
  }

  &[data-attempts="1"] {
    // fill: #ffed72 !important;
    fill: #c4b340 !important;
    stroke: none;
    .small-helper {
      fill: #f5e158;
    }
  }
  &[data-attempts="2"] {
    // fill: #ffa3a3 !important;
    fill: #ba4360 !important;
    stroke: none;
    .small-helper {
      fill: #ba4360;
    }
  }
}

.complete:hover {
  cursor: default;
}

.quiz-country {
  &:focus {
    outline: none !important;
  }
  path {
    stroke: #b8e5ff;
    // stroke-width: 3.5px !important;
  }

  &:not(.complete) {
    &:hover {
      fill: #6dc69a;
      outline: 0;
      cursor: pointer;
      .assist-helper {
        opacity: 0.1;
      }
    }
    .assist-helper {
      opacity: 0.3;
    }
  }

  &[data-mode="choice"] {
    fill: rgb(80, 181, 92);
  }

  &[data-assist="true"] {
    pointer-events: bounding-box;
    z-index: 1;
  }
  &[data-hidden="true"] {
    fill: rgba(111, 111, 111, 0.317) !important;
    stroke: none !important;
    pointer-events: none !important;
  }
  &[data-island="true"] {
    fill: rgba(62, 125, 83, 0.3) !important;
  }
  &[data-context="true"] {
    fill: rgba(25, 38, 29, 0.5) !important;
  }
  &[data-small="true"] {
  }
}

.game-map {
  // overflow: unset;

  display: flex;
  // height: 85%;
  // background-color: rgba(57, 81, 148, 0.048);
  // min-width: 800px;
  overflow: visible;
  position: relative;

  .quiz-country .assist-helper {
    stroke: #3d5b6d;
    stroke-width: 0.4px;
  }

  &[data-locked="true"] {
    pointer-events: none;

    .quiz-country {
      pointer-events: none;
    }

    g:hover {
      cursor: default;
    }
  }

  &[data-continent="North America"] {
    .quiz-country path {
      stroke: #a9e0ff;
      stroke-width: 0.4px !important;
    }
    &[data-quiz_name="states"] {
      .quiz-country path {
        stroke: #a9e0ff;
        stroke-width: 0px !important;
      }
    }
    // &[data-mode="multiple"] {
    //   g {
    //     &[data-answer="true"] {
    //       path {
    //         stroke-width: 0px !important;
    //       }
    //     }
    //   }
    // }
  }
  &[data-continent="Africa"] {
    g path {
      // stroke: rgb(182, 224, 204);
      stroke-width: 0.1px;
    }
  }
  &[data-continent="Asia"] {
    g path {
      // stroke: rgb(182, 224, 204);
      stroke-width: 1.5px;
    }
  }
  &[data-continent="Europe"] {
    g path {
      // stroke: rgb(182, 224, 204);
      stroke-width: 0.5px;
    }
  }

  &:not([data-mode="multiple"]) {
    .complete-reveal {
      fill: #ba4360 !important;
      .small-helper {
        fill: #ba4360;
      }
      // animation: circle-complete 1s;
    }
  }

  &[data-mode="multiple"] {
    overflow: hidden;
    g {
      // fill: #92ccbe;
      // fill: rgba(182, 224, 204, 0.8);
      &[data-answer="true"] {
        fill: #f5e158 !important;

        &[data-island="true"] {
          fill: #f5e058b4 !important;
        }

        &[data-small="true"] {
          outline: 8px solid #f5e158;
          transform-origin: center;
        }

        path {
          stroke: white !important;
          stroke-width: 2px;
        }
      }

      &[data-correct="true"] {
        fill: #67c5b5 !important;
      }
      &[data-correct="false"] {
        fill: #c83d5f !important;
      }
    }
  }
}

.game-map .other[data-modify="true"] {
  fill: rgba(111, 111, 111, 0.317);
}

.quiz-line {
  pointer-events: none !important;
}

.home-main-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1000px;
  height: 100%;
  margin-top: 100px 26px 0 26px;
  align-items: center;
  margin: 0 16px;

  // #home-slogan {
  //   background-color: rgba(30, 30, 60, 0.8);
  // padding: 10px;
  // border-radius: 12px;
  // }

  .home-buttons {
    flex: 1;
    max-width: 400px;
  }

  .home-world {
    flex: 1;
    margin: 0 auto;
    width: 500px;
    height: 500px;
  }

  @media screen and (max-width: 900px) {
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;

    .home-world {
      width: 70%;
    }

    .home-buttons {
      margin-top: -300px;
      text-shadow: 8px 0px 8px rgba(0, 0, 0, 0.72);
    }
  }

  @media screen and (max-width: 400px) {
    .home-buttons {
      margin-top: -250px;
    }
  }

  @media screen and (max-width: 700px) {
    #home-slogan {
      width: 100%;
      // max-width: 80%;
    }

    .home-world {
      width: auto;
      max-width: 80%;
    }
  }
}

.scrollpage1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 5rem;
  justify-content: center;

  @media screen and (max-width: 700px) {
    .mode-card {
      margin: 0;
      margin-top: 20px;
    }
  }
}

.header-container {
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: #a9e0ff;
}

.Header {
  background: rgba(15, 55, 81, 0.3);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  position: absolute;
  padding-top: 10px;
  height: 35px;
  width: 100%;
  align-self: center;
  // max-width: 80%;
  font-family: "Nunito";
  white-space: nowrap;
  z-index: 1;
}

// span {
//   display: flex;
//   align-items: center;
// }

// > span {
//   flex: 0.22;
//   min-width: 200px;
//   max-width: 300px;
// }

.header-logo {
  align-self: center;
  margin-right: 10px;
  img {
    vertical-align: unset;
  }
  @media screen and (max-width: 900px) {
    position: absolute;
    left: 50px;
  }
}

#main-nav {
  a {
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
    color: white !important;
    margin: 0;
    padding: 0 10px;
    letter-spacing: 0.15em;
    &:hover {
      cursor: pointer;
      color: whitesmoke;
    }
  }
}

#hamburger-input {
  display: none;
}

#hamburger-input:checked + #hamburger-menu #sidebar-menu {
  visibility: visible;
  right: 0;
}

#hamburger-menu {
  position: absolute;
  right: 50px;
  z-index: 3;
  width: 24px;
  margin-top: 10px;
  height: 20px;
  display: none;
  background: linear-gradient(
    to bottom,
    white,
    white 20%,
    #3d0e6100 20%,
    #3d0e6100 40%,
    white 40%,
    white 60%,
    #3d0e6100 60%,
    #3d0e6100 80%,
    white 80%,
    white 100%
  );
}

#sidebar-menu {
  height: 100px;
  width: 100%;
  background: #092335;
  z-index: 9;
  a,
  li {
    margin-top: 20px;
    color: white;
    list-style: none;
  }
}

#hamburger-input:checked ~ #overlay {
  visibility: visible;
  opacity: 0.4;
}

#hamburger-menu #sidebar-menu {
  visibility: hidden;
  position: fixed;
  top: 0;
  right: -200px;
  width: 200px;
  height: auto;
  transition: 0.3s;
}

#overlay {
  position: fixed;
  top: 0;
  // right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  overflow: hidden;
  background: black;
  z-index: 2;
}

@media screen and (max-width: 900px) {
  #main-nav {
    display: none;
  }
  #hamburger-menu {
    display: inline;
  }
}

.header-nav {
  width: 80%;
  margin-left: 50px;
  // display: flex;
  /* justify-content: space-around; */
  color: white;
  font-size: 24px;
  font-family: "Baloo Chettan", cursive;
  box-sizing: border-box;
}

.header-nav p {
  padding-right: 4%;
}

.scroll-cards-modes {
  display: flex;
  justify-content: center;
}

.cards-show {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 80rem;

  .mode-card {
    max-width: 19rem;
    display: flex;

    .card-top {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      padding: 30px 20px;
      max-height: 200px;

      h5 {
        justify-self: flex-end;
      }
    }
    .card-bottom {
      flex: 1;
      img {
        height: 100%;
      }
    }
  }

  > div {
    margin: 0 20px;
  }
  /* width: 1200px; */
}

// @media (max-width: 1200px) {
//     .home-page-top-content {
//       flex-direction: column-reverse;
//       justify-content: center;
//     }
//     #world-img {
//       max-width: 500px;
//       max-height: 500px;
//       width: 100%;
//       height: 100%;
//     }
//   }

.scroll-quizzesButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 5rem;
  max-width: 40rem;
  height: 80px;

  @media screen and (max-width: 700px) {
    max-width: 80%;
  }

  svg {
    height: 60%;
  }

  button {
    background-color: #fff;
    border: 1px solid #285979;
    color: #285979;
    font-size: 18px;
    border-radius: 35px;
    width: 270px;
    height: 80%;
    margin: 0 10px;
    transition: 0.4s;
    // text-align: center;

    &:hover {
      cursor: pointer;
      // background-color: #e5f5ff;
    }
  }
}

.multipleChoice {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  margin-top: 100px;
  width: auto;
  height: 100%;

  &[data-selected="false"] {
    .box:hover {
      cursor: pointer;
      background-color: rgb(255, 254, 185);
    }
  }

  .animateIn {
    .box:nth-child(1) {
      animation: ease-in-out animateIn 0.4s;
      animation-delay: 0s;
    }
    .box:nth-child(2) {
      animation: ease-in-out animateIn 0.4s;
      animation-delay: 0.15s;
    }
    .box:nth-child(3) {
      animation: ease-in-out animateIn 0.4s;
      animation-delay: 0.35s;
    }
  }

  .box {
    display: flex;
    align-items: center;
    color: #454545;
    height: 50px;
    width: 300px;
    margin: 10px;
    border-radius: 8px;
    background-color: white;
    border: none;
    font-weight: bold;
    letter-spacing: 0.095em;
    font-family: Nunito;

    &:focus {
      outline: none;
    }
    div:first-of-type {
      font-size: 20px;
      align-self: left;
      margin-left: 10px;
      left: 2px;
      color: #989898;
    }

    div:last-of-type {
      font-size: 24px;
      width: calc(100% - 40px);
    }
  }

  .correct {
    .box[data-status="correct"] {
      animation: ease-in correct 1.3s;
    }

    // .key {
    //   color: #73A65C;
    // }
  }
  .wrong {
    .box[data-status="wrong"] {
      animation: ease-in wrong 1.6s;
    }

    // .key {
    //   color: #AF2D2D;
    // }
  }
}

@keyframes circle-complete {
  // pulse animation
  0% {
    transform: scale(0.9);
    opacity: 0.5;
  }
  70% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 0.5;
  }
}

@keyframes animateIn {
  50% {
    width: 150px;
    height: 25px;
  }
  100% {
    width: 300px;
    height: 50px;
  }
}

@keyframes correct {
  30% {
    background-color: #98e575;
    box-shadow: 0px 0px 1px 8px #d2fecb;
  }
}

@keyframes wrong {
  30% {
    background-color: #f55858;
    box-shadow: 0px 0px 0px 7px #ffb6b6;
  }
  70% {
    background-color: #f55858;
    box-shadow: 0px 0px 0px 7px #ffb6b6;
  }
}

.wrongSelect {
  position: absolute;
  animation: shake 0.2s;
  -webkit-animation: shake 0.2s;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
    -webkit-transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
    -webkit-transform: translate(-1px, -2px) rotate(-1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
    -webkit-transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
    -webkit-transform: translate(1px, -1px) rotate(1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
    transform: translate(-1px, -1px) rotate(1deg);
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
    -webkit-transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
    -webkit-transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
  }
}

@keyframes pulse-reveal {
  0% {
    fill: #ff6969 !important;
    transform: scale(3.5);
    box-shadow: 0 0 0 0 rgba(217, 51, 51, 0.7);
    background-color: rgba(217, 62, 51, 0.4);
  }

  70% {
    transform: scale(0.95);
    box-shadow: 0 0 0 20px rgba(217, 62, 51, 0);
  }

  100% {
    fill: unset;
    transform: scale(3.5);
    box-shadow: 0 0 0 0 rgba(217, 90, 51, 0);
    background-color: rgba(217, 84, 51, 0);
  }
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
    background-color: rgba(51, 217, 178, 0.4);
  }

  70% {
    transform: scale(2.5);
    box-shadow: 0 0 0 20px rgba(51, 217, 178, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
    background-color: rgba(51, 217, 178, 0);
  }
}

@keyframes wipey {
  0% {
    background-color: rgba(243, 255, 206, 0);
  }
  50% {
    background-color: rgba(243, 255, 206, 1);
  }
  100% {
    background-color: rgba(243, 255, 206, 0);
  }
}

.quiz-circle {
  z-index: 5;
  display: none;
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 25px;
}

.circle-complete {
  position: absolute;
  pointer-events: none;
  display: block;
  /* animation-name: waveyz; */
  background-color: none;
  transform-origin: center;
  animation-duration: 1.5s;
  stroke-width: 8px;
  animation-name: pulse-green;
}

.circle-reveal {
  position: absolute;
  pointer-events: none;
  display: block;
  /* animation-name: waveyz; */
  background-color: none;
  transform-origin: center;
  animation-duration: 2.5s;
  stroke-width: 8px;
  animation-name: pulse-reveal;
}
